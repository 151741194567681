const $ = require('jquery');

module.exports = function() {
    $('*[data-fill]').click((e) => {
        e.preventDefault();
        e.stopPropagation();

        const targets = $(e.target).attr('data-fill');
        const elements = $(targets);

        $(elements).val($(e.target).html());
    });
}
