const $ = require('jquery');

module.exports = function() {
    $('*[data-modal]').click((e) => {
        e.preventDefault();
        e.stopPropagation();

        let id = $(e.target).attr('data-modal');
        let modal = $(id);

        modal.modal('toggle');
    });
}
