const Choices = require('choices.js');
const $ = require('jquery');

const addChoices = () => {
    $('*[data-jschoice]').each(function(key, item) {
        new Choices(item);
    });
}

module.exports = function() {
    $(addChoices)

    $('*[data-collection]').on('collection.update', addChoices);
}
